

#codeExample {
    height: 100%;
    max-height: 49vh;
    position:flex !important;
    background:#eee;
}

#codeExample:fullscreen {
    position:fixed !important;
    height: 100% !important;
    width:100% !important;
    padding:0 !important;
    margin:0 !important;
    max-height: none;
}

::backdrop {
    position:fixed;
    inset:0;
    background:#eee;
}

.cm-editor {
    position:absolute;
    display:flex;
    border: 1px solid silver;
    max-height: 49vh;
    height: 100%;
    background:#eee;
}

.cm-empty {
    outline: 1px solid #c22;
}
.cm-empty.cm-focused {
    outline: none;
}
.cm-editor pre.cm-placeholder {
    color: #999;
}

.cm-fullscreen {
    position: fixed;
    top: 40px; left: 0; right: 0; bottom: 0;
    height: auto;
    max-height: 100%;
    z-index: 9;
}

#loadExample {
    display: none
}

.codeBox {
    height: 100%;
}

.codeControl {
    display:flex;
    height: auto;
    width: 100%;
}

.consoleBox {
    display: flex;
    height: 30%;
}

.consoleError {
    all: unset;
    width: 50%;
    background-color: black;
    color: red;
}

.consoleResult {
    all: unset;
    width: 50%;
    background-color: black;
    color: white;
}

.console {
    width: 100%;
    height: 200px;
    margin: 5px;
    padding: 5px;
    overflow: auto;
    color: white;
    background-color: black;
    color: black;
    font-family: "Courier New";
    font-weight: bold;
}

.message {
    width: 50%;
}

.errorMsg {
    color: red;
    font-weight: bold;
}

