
html {
    height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
}
.mainwindow {
    height: 100%;
    background-color: #f1f1f1;
}
.ui.grid {
    min-height: 100%;
}
.ui.menu {
    background-color: #d4d4d4;
}

#sidemenu {
    background-color: #e8e8e8;
    padding-top: 30px;
}

.queryPadding {
    padding-left: 20px;
    padding-right: 20px;
}
.loaderSpacer {
    height: 60px;
}

